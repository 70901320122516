.voyage-gantt{
    .chart-label{
        display: inline-block;
        width: 100px;
    }

    .vertical-line{    
        border-left: 2px solid rgb(180, 40, 40);
        position: absolute;
        top: -7rem;
        z-index: 999;
        height: 2rem;
        overflow: hidden;
    }

    .vertical-line-text{
        // border-left: 2px solid rgb(180, 40, 40);
        // left: 250px;
        // position: absolute;
        // height: calc(100% - 2rem);
        // top: 1rem;
        // z-index:999;
        
        // position: absolute;
        // align-self: stretch;
        // width: 1px;
        // display: block;
        // margin-left: 20px;
        // margin-right: 20px;
        // z-index:100;
    }

    .gt-timeline{
        display: block;
        height: 20px;
        position: relative;  
        border-radius: 4px;
        float: left;

        &.old-voyage{
            background-color: rgb(94, 91, 91);
        height: 25px;
        }

        &.current-voyage{
            background-color: forestgreen;
        height: 25px;
        }

        &.future-voyage{
            background-color: #ffcc00;
        height: 25px;
        }
    }

    .voyage-tooltip {
        .v-tooltip{
            display: none;
            position: absolute;
            color: #000;
            background: #fff;
            padding: 5px;
            font-family: "Candara";
        }
        position: relative;
        
        &:hover {
            .v-tooltip {
                display: block;
                text-align: center;
            }
        }
        
    }

    
}