.my-table{
    .table thead tr:last-child th {
        border-top: 0.5px solid #dbdfea;
    }
    thead th{
        font-size: 12px;
        color: #1c2b46 !important;
        font-weight: bold !important;
        line-height: 19.8px !important;
    }
    tbody td{
        color: #8094ae;
        font-size: 13px;
        border-bottom: none !important;
        border-top-width: 0.5px !important;
    }
    .nk-tb-col{
        padding: 0.8rem 0.5rem !important;
    }
    .table td:first-child, .table th:first-child {
        padding-left: 1.25rem !important;
    }
    .kt-quick-search__input{
        background: transparent !important;
    }

    .delete-row-trigger{
        padding: 2px;
        border-radius: 50px;
        height: 22px;
        width: 22px;
        display: inline-block;
        transition: all ease-out 0.5s;
        cursor: pointer;
        
        i{
            transition: all ease-out 0.5s;
            color: #999;
        }
    }

    .delete-row-trigger:hover{
        background-color: rgba(40,40,40,0.075);
        i{
            top: 2px;
            position: relative;
        }

        *{
            color: #dc3545 !important;
        }
        
    }
}