body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-card-container{
  min-height: calc(100vh - 150px);
}

body.ReactModal__Body--open{
  overflow: hidden;
}

body.ReactModal__Body--open .nk-wrap{
  background:  #f5f6fa !important
}

body.ReactModal__Body--open .ReactModalPortal .nk-content{
  max-width: 960px;
    margin-left: auto;
    margin-right: auto;

}

.pointer, .clickable{
  cursor: pointer;
}

.gray-hover{
  cursor: pointer;
}

.gray-hover:hover{
  background: #f5f6fa;
}

.nav-item{
  cursor: pointer;
}

.font-weight-500{
  font-weight: 500 !important;
}


.vessel-image-container button{
  visibility: hidden;
}

.vessel-image-container:hover button{
  visibility: visible;
}

.center{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}


.modal{
  overflow-y: auto;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.modal::-webkit-scrollbar {
  display: none;
}


small, .small.small.small{
  font-size: 85%;
}

.max-h-75{
  max-height: 75vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.max-h-75::-webkit-scrollbar {
  display: none;
}